@import "./breakpoints.scss";

.project-holder{
  max-height: calc(100vh + 200px);
  overflow: hidden;
  position: relative;
  //height: 16000px;
  max-width: 1400px;
  z-index: 1;
  opacity: 0;
  &.isLoad{
    background: white;
    opacity: 1;
  }
  &.desktop{
    display: none;
  }

}
@media (min-width: #{346px}) {
  .project-holder {
    margin: -170px auto 0 auto;
  }
}
@include sm{
  .project-holder{
    margin: -223px auto 0 auto;
  }
}
@include mm{
  .project-holder{
    margin: -160px auto 0 auto;
  }
}
@media (min-width: #{400px}) {
  .project-holder {
    margin: -120px auto 0 auto;
  }
}
@include lm{
  .project-holder{
    margin: -130px auto 0 auto;
  }
}
@media (min-width: #{458px}) {
  .project-holder {
    margin: -80px auto 0 auto;
  }
}
@media (min-width: #{518px}) {
  .project-holder {
    margin: -40px auto 0 auto;
  }
}
@media (min-width: #{518px}) {
  .project-holder {
    margin: 0px auto 0 auto;
  }
}
@include st{
  .project-holder{
    margin: 10px auto 0 auto;
  }
}
@include lg {
  .project-holder{
    //border: 1px solid red;
    margin: -160px auto 0 auto;
    position: static;
    overflow-y: hidden;
    min-height: 896px;
    .iframe-holder{
      //max-height: calc(100vh - 100px);
      overflow: hidden;
      position: absolute;
      left: 0;
      width: calc(100vw - 17px);
      //border: 1px solid red;
    }
    &.mobile{
      display: none;
    }
    &.desktop{
      display: block;
    }
  }
}


@media (min-width: #{1130px}) {
  .project-holder {
    margin: -160px auto 0 auto;
  }
}
@media (min-width: #{1277px}) {
  .project-holder {
    margin: -110px auto 0 auto;
  }
}
@media (min-width: #{1337px}) {
  .project-holder {
    margin: -46px auto 0 auto;
  }
}
