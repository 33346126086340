@import "node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "styles/breakpoints";
@import "styles/projects";
@import "styles/loader";
$hamburger-layer-width: 22px;
* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

}
a {
  all: unset;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
/* You can add global styles to this file, and also import other style files */
body{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}
h3{
  color: #2E2E2E;
}
span{
  color: #2E2E2E;
}
p{
  color: #2E2E2E;
}
.projects-holder{
  display: none;
}
@include lg {
  .projects-holder{
    margin-top: 80px;
    display: block;
  }
}
